import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

export const Quote = () => {
    const { user } = useAuth0();

    return (
        <div className="wrap-quote h-100 w-100">
            <iframe title="quote" height="100%" width="100%" src={user?.quote_url} />
        </div>
    )
}

export default withAuthenticationRequired(Quote, {
    onRedirecting: () => <Loading />,
  });
